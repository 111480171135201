<script setup lang="ts">
import { ref, onMounted } from 'vue'
import RoomieCheckbox from '@lahaus-roomie/roomie/src/components/RoomieCheckbox/index.vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import type { Props, DataEvent } from './types'
import type { LocaleCode } from '@/config/locale-domains'
import { POLICIES } from '@/utils/segment'
import './_index.scss'

const description = ref('')

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue'])

const { t, locale } = useI18n()

const checkboxId = ref('')

onMounted(async () => {
  const { $getLegalPolicies } = useNuxtApp()
  const data = await $getLegalPolicies(locale.value)
  description.value = data

  checkboxId.value = `checkbox-legal-policies-${crypto.randomUUID()}`
})

const handleCheckboxChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.checked)
  getLegalPoliciesAcceptedProperties()
}

const sourcePlatform = (isMobile: boolean): string => {
  return isMobile ? 'WEB_MOBILE' : 'DESKTOP'
}

const getPoliciesIds = () => {
  const termsAndConditiosCode = POLICIES['terminos y condiciones'][locale.value as LocaleCode]
  const privacyPolicyCode = POLICIES['politicas de tratamiento de datos'][locale.value as LocaleCode]

  return locale.value === 'co'
    ? privacyPolicyCode
    : [termsAndConditiosCode, privacyPolicyCode].join(',')
}

const getLegalPoliciesAcceptedProperties = () => {
  if (!props.phone) return

  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isMobile = breakpoints.smaller('md').value
  const data = {
    legal_policies_id: getPoliciesIds(),
    screen: props.screen,
    screen_cta: props.screenCta,
    phone: props.phone,
    device_platform: sourcePlatform(isMobile)
  } as DataEvent

  snowPlowEvent(data)
}

const snowPlowEvent = (data: DataEvent) => {
  // @ts-ignore
  const { $trackEvent } = useNuxtApp()

  $trackEvent({
    name: 'legal_policies_accepted',
    version: '1-0-0',
    data
  })
}

</script>

<script lang="ts">
export default {
  name: 'LegalPolicies'
}
</script>

<template>
  <section class="legal-policies mb-20 min-h-[30px]">
    <div class="legal-policies__content flex items-start gap-3">
      <RoomieCheckbox
        :id="checkboxId"
        data-lh-id="checkbox-legal-policies"
        :checked="modelValue"
        @change="handleCheckboxChange" />

      <div
        v-if="description"
        v-sanitize="description"
        class="legal-policies__description body-3-m" />
    </div>

    <p
      v-if="showWarning"
      class="body-3-m text-error-600 mt-8">
      {{ t('policiesRequired') }}
    </p>
  </section>
</template>

<i18n src="./i18n.json" lang="json" />
